<!--
- bgText: text - not used
- backgroundImage: image - not used
-->
<template>
	<div class="cs-mosaic-matrix-block cs-block" :class="`${styleClass}${hasBg}`">
        <div class="cs-block-base">
			<div class="container">
				<div class="header-content">
                    <h2 v-if="data.title" class="cs-title">
                        <ColorSplit :data="data.title"/>
                    </h2>
					<h3 v-if="data.subTitle" class="cs-sub-title">{{ data.subTitle }}</h3>
					<span v-if="data.text" class="cs-text" v-html="data.text"></span>
					<div v-if="data.buttons && ((typeof data.buttons == 'object' && Object.keys(data.buttons).length) || (Array.isArray(data.buttons) && data.buttons.length))" class="buttons block-buttons">
                        <Buttons :data="data.buttons" :cs_style="data.style" />
					</div>
				</div>
				<div class="row mosaic-matrix-cards" v-if="data.cards">
					<div v-for="(card, index) in data.cards" :key="`mosaic-card-${index}`" class="" :class="[(Object.values(data.cards).length > 4) ? 'col-sm-6 col-lg-4 col-xl-3' : 'col-sm-6 col-lg' ]">
						<div class="mosaic-matrix-card">
							<div>
								<div class="image-container">
									<img :src="card.image" />
								</div>
								<div class="text-content">
									<h6>
										{{ card.title }}
									</h6>
									<div class="cs-text" v-html="card.text"></div>
									<div v-if="card.linkText" class="block-buttons">
										<Buttons :data="[{url: card.url, buttonText: card.linkText}]" :cs_style="data.style" /> 
									</div>
								</div>
							</div>
						</div>
					</div>
				</div> 
			</div>
		</div> 
	</div>
</template>

<script>
	import {computed} from '../libs/common-fn';

export default {
		name: 'MosaicMatrix',
		components: {
            ColorSplit: () => import("../components/CSUtilsColorSplit.vue"),
			Buttons: () => import("../components/CSUtilsButtons.vue")
		},
		props: {
			data: {
				type: Object,
			default: () => {},
			},
		},
		computed: {
            ...computed('MosaicMatrix'),
            hasBg() {
                if (this.data.style == 'color') return ' has-background';

                return '';
            }
        }
	};
</script>

<style lang="scss" scoped>
    @import '../styles/main.scss';

	.cs-mosaic-matrix-block {
		.header-content{
			text-align:center;
			margin-bottom:30px;	
		}
		.mosaic-matrix-card {
            margin-bottom: -30px;
            height: calc(100% - 30px);
		}
		.mosaic-matrix-card a {
            color: inherit;
            text-decoration: none;
		}
        .mosaic-matrix-cards{
            margin-top: 30px;
            .mosaic-matrix-card{
                background: $bg_dark;
                box-shadow: rgba(56,134,247, 0.3) 0px 8px 24px;
                border-radius: 15px;
                padding: 8px;
                overflow: hidden;
                margin-bottom: 30px;
                position: relative;
                .image-container{
                    position: relative;
                    text-align: left;
                    &:after {
                        display: block;
                        content: '';
                        padding: 30%;
                    }	
                    img {
                        width: 100%;
                        border-radius: 15px;
                        object-fit: cover;
                        position: absolute;
                        height: 100%;
                    }
                }
                ::v-deep {
                    .button-group {
                        padding-right: 0;
                    }
                }
                .text-content{
                    padding: 15px 5px;
                    text-align: center;
                }
            }
    
        }
        &.cs-style- {
            &color {
                @include cs-block-color;
                .cs-sub-title{
                    color:$sub_title_color;
                }
                *, ::v-deep *{
                    color:white;
                }
                .mosaic-matrix-card {
                    background-color: $theme_bg_color;

                    .text-content * {
                        color: black !important;
                    }

                    .cs-text {
                        ::v-deep {
                            * {
                                color: black !important;
                            }
                        }
                    }
                }
            }

            &light {
                @include cs-block-light;
                .cs-sub-title{
                    color: $sub_title_color_light;
                }
            }

            &dark {
                @include cs-block-dark;
                .cs-sub-title{
                    color: $sub_title_color_dark;
                }
            }
        }
	}
</style>