'use strict';

export const computed = function(component_name) {
    const computed_fn = {
        BlogPostArchive: {
            styleClass() {
                let result = ''

                if(this.data.style) result += `cs-style-${this.data.style}`;

                return result
            },
        },
        BlogPostContent: {},
        BlogPostPreview: {
            styleClass() {
                let result = ""
    
                if (this.data.style) result += `cs-style-${this.data.style}`
    
                return result
            },
        },
        Calculation: {
            styleClass(){
                let result = ''
    
                if(this.data.style) result += `cs-style-${this.data.style}`;
    
                return result
            }
        },
        ContactForm: {
            styleClass(){
                let result = ''

                if(this.data.style) result += `cs-style-${this.data.style}`;

                return result
            },
        },
        CookieBanner: {},
        Embed: {
            styleClass(){
                let result = ''
    
                if(this.data.style) result += `cs-style-${this.data.style}`;
    
                return result
            }
        },
        FAQ: {
            styleClass() {
                let result = ""
    
                if (this.data.style) result += `cs-style-${this.data.style}`
    
                return result
            },
        },
        FindUs: {
            styleClass() {
                let result = ""
    
                if (this.data.style) result += `cs-style-${this.data.style}`
    
                return result
            },
        },
        FooterMenu: {
            homeUrl() {
                return window.location.origin
            }
        },
        Form: {
            styleClass(){
                let result = ''
    
                if(this.data.style) result += `cs-style-${this.data.style}`;
    
                return result
            }
        },
        HeaderMenu: {},
        HeroSection: {
            styleClass() {
                let result = ''

                if (this.data?.backgroundImage)
                    result += 'has-background ';

                if(this.data.style) result += `cs-style-${this.data.style}`

                    return result
            },
            backgroundImage() {
                if (this.data?.image){

                    return {
                        ['background-image']: `url("${this.data.image}")`,
                        ['background-repeat']: 'no-repeat',
                        ['background-size']: 'cover'
                    }
                }
            }
        },
        ImageBlock: {
            styleClass(){
                let result = ''
    
                if(this.data.style) result += `cs-style-${this.data.style}`;
    
                return result
            },
    
        },
        ImageSlider: {
            styleClass() {
                let result = ""
    
                if (this.data.style) result += `cs-style-${this.data.style}`
                if (
                    this.data?.title?.length ||
                    this.data?.text?.length ||
                    this.data?.subTitle?.length
                )
                    result += result?.length ? " has-content" : "has-content"
    
                return result
            },
            imageSlides() {
                let result = [];
                if (this.data?.items && typeof this.data.items == 'object') result = Object.values(this.data.items);
                return result;
            }
        },
        InteractiveContactInterface: {
            styleClass() {
                let result = ""
    
                if (this.data.style) result += `cs-style-${this.data.style}`
    
                return result
            },
            coordinates() {
                return [this.data.markerLat, this.data.markerLon]
            },
        },
        Job: {
            styleClass() {
                let result = ""
    
                if (this.data.style) result += `cs-style-${this.data.style}`
    
                return result
            },
        },
        LandingPage: {},
        LogoReel: {
            styleClass() {
                let result = ""
    
                if (this.data.style) result += `cs-style-${this.data.style}`
    
                return result
            },
            imageSlides() {
                let result = [];
                if (this.data?.list && typeof this.data.list == 'object') result = Object.values(this.data.list);
                return result;
            }
        },
        MapBlock: {
            styleClass() {
                let result = ""
    
                if (this.data.style) result += `cs-style-${this.data.style}`
    
                return result
            },
            coordinates() {
                let latitude =0, longitude=0;
                
                if(this.data?.markers?.[0]){
                    latitude = this.data.markers[0].latitude
                    longitude = this.data.markers[0].longitude   
                }
    
                return [latitude, longitude]
            },
            computedMarkers() {
                let result = [];
    
                if (this.data.markers && Object.keys(this.data.markers).length) {
                    for (const [index, coords] of Object.entries(this.data.markers)) {
                        result[index] = [coords.latitude, coords.longitude];
                    }
                }
    
                return result;
            }
        },
        MosaicMatrix: {
            styleClass(){
                let result = ''

                if(this.data.style) result += `cs-style-${this.data.style}`;

                return result
            }
        },
        MosaicMotionModule: {
            styleClass(){
                let result = ''

                if(this.data.style) result += `cs-style-${this.data.style}`;

                return result
            },
            imageSlides() {
                let result = [];
                if (this.data?.cards && typeof this.data.cards == 'object') result = Object.values(this.data.cards);
                return result;
            }
        },
        MulticolumnText: {
            styleClass(){
                let result = ''
    
                if(this.data.style) result += `cs-style-${this.data.style}`;
    
                return result
            },
            rootClasses() {
                let result = ''
    
                if (this.data.globalTextAlign) result = "cs-align-" + this.data.globalTextAlign
    
                return result
            },
            changeColumns() {
                if (this.data.columns == 6) return `col-md-${this.data.columns}`
    
                return `col-lg-${this.data.columns}`
            }
        },
        NumericalDialogue: {
            styleClass() {
                let result = ""
    
                if (this.data.style) result += `cs-style-${this.data.style}`
    
                return result
            },
        },
        OrbitalSlider: {
            styleClass(){
                let result = ''
    
                if(this.data.style) result += `cs-style-${this.data.style}`;
    
                return result
            },
            imageSlides() {
                let result = [];
                if (this.data?.list && typeof this.data.list == 'object') result = Object.values(this.data.list);
                return result;
            }
        },
        PromoPanel: {
            rootClasses() {
                let result = ''

                if(this.data.textAlign) result+= " cs-align-"+this.data.textAlign;

                return result;
            },
            styleClass() {
                let result = ""
    
                if (this.data.style) result += `cs-style-${this.data.style}`
    
                return result
            },
        },
        ShowcaseCircles: {
            styleClass(){
                let result = ''

                if(this.data.style) result += `cs-style-${this.data.style}`

                return result
            }
        },
        Status: {
            rootClasses() {
                let result = {
                    ['cs-status']: true,
                    ['has-background']: true,
                    ['theme-block']: true,
                }
    
                if (this.data?.hasBackground)
                    result = {
                        ...result,
                        ...{
                            ['theme-bg-colored']: true
                        }
                    }
    
                return result
            },
            allKeys() {
                let keys = []
                this.dataStatus.forEach(status => {
                    status.keys.forEach(key => {
                        if (keys.length == 0) keys.push(key)
    
                        else {
                            if (!keys.some(obj => obj.slug === key.slug)) {
                                keys.push(key)
                            }
                        }
                    })
                })
                return keys
            },
    
        },
        Table: {
            styleClass(){
                let result = ''
    
                if(this.data.style) result += `cs-style-${this.data.style}`
    
                return result
            },
        },
        TextBlock: {
            styleClass() {
                let result = ""
    
                if (this.data.style) result += `cs-style-${this.data.style}`
    
                return result
            },
            rootClasses() {
                let result = ""
    
                if (this.data.textAlign) result += "cs-align-" + this.data.textAlign
    
                return result
            },
        },
        Video: {
            styleClass(){
                let result = ''
                
                if(this.data.style) result += `cs-style-${this.data.style}`
                    
                    return result
            },
            overlayClasses() {
                let result = {
                ["cs-video-overlay"]: true,
                };

            if (this.videoPlayedOnce) result["video-overlay-hidden"] = true;

                return result;
            }
        },
        VisualNarrativeSegment: {
            styleClass() {
                let result = ""
    
                if (this.data.style) result += `cs-style-${this.data.style}`
    
                return result
            },
            rootClasses() {
                let result = ""
    
                if (this.data.flipOrientation) result += "cs-block-flip "
                if (this.data.columns) result += `cs-distribution-${this.data.columns}`
    
                return result
            },
        }
    }
    
    return computed_fn[component_name]
}